
import * as arrows from '/arrows'
import { Undoer } from 'undoer'

export default class Editor {
  constructor (selector) {
    this.el = document.querySelector(selector)
    this.img = this.el.querySelector('img.target')
    this.renderer = this.el.querySelector('canvas.renderer')
    this.ctx = this.renderer.getContext('2d')

    document.addEventListener('keydown', e => this.keydown(e))

    this.img.addEventListener('mousedown', e => this.mousedown(e))
    this.mousemove = this.mousemove.bind(this)
    this.mouseup = this.mouseup.bind(this)

    this.activeStyle = arrows.SimpleRounded
  }

  reset (image, type, filename) {
    this.source = image
    this.metadata = { type, filename }
    this.arrows = []
    this.state = new Undoer((data) => {
      this.arrows = [...data]
      this.refresh()
    }, [])

    this.refresh()
  }

  refresh () {
    this.refreshing = true
    if (!this.source) {
      return
    }

    this.ctx.clearRect(0, 0, this.renderer.width, this.renderer.height)
    if (this.renderer.width !== this.source.width || this.renderer.height !== this.source.height) {
      this.renderer.width = this.source.width
      this.renderer.height = this.source.height
    }
    if (!this.dragging) {
      this.ctx.drawImage(this.source, 0, 0)
      this.arrows.forEach(([a, b]) => this.drawArrow(this.ctx, a, b, null))
    }

    if (this.dragging) {
      this.drawArrow(this.ctx, this.startPosition, this.endPosition, null)
    }

    if (!this.dragging) {
      const data = this.renderer.toDataURL(this.metadata.type)
      this.img.src = data
    }
    this.refreshing = false
  }

  drawArrow (ctx, a, b, style) {
    ctx.save()
    this.activeStyle(ctx, a, b, style)
    ctx.restore()
  }

  mousedown (e) {
    if (e.button !== 0 || this.refreshing) {
      return
    }
    const rect = this.img.getBoundingClientRect()

    document.addEventListener('mousemove', this.mousemove)
    document.addEventListener('mouseup', this.mouseup)

    e.preventDefault()
    this.dragginOrigin = { x: e.clientX, y: e.clientY }
    this.startPosition = { x: e.clientX - rect.left, y: e.clientY - rect.top }
    this.endPosition = { x: this.startPosition.x, y: this.startPosition.y }
    this.dragging = true
    this.refresh()

    this.renderer.style.display = 'block'
    this.renderer.style.top = `${rect.top}px`
    this.renderer.style.left = `${rect.left}px`
  }

  mousemove (e) {
    if (this.dragging) {
      this.endPosition = { x: this.startPosition.x + e.clientX - this.dragginOrigin.x, y: this.startPosition.y + e.clientY - this.dragginOrigin.y }
      this.refresh()
    }
  }

  mouseup (e) {
    document.removeEventListener('mosuemove', this.mosuemove)
    document.removeEventListener('mouseup', this.mouseup)
    if (this.dragging) {
      this.dragging = false

      const rect = this.img.getBoundingClientRect()

      if (rect.width !== this.source.width) {
        const scale = this.source.width / rect.width
        this.startPosition.x *= scale
        this.startPosition.y *= scale
        this.endPosition.x *= scale
        this.endPosition.y *= scale
      }

      this.arrows.push([this.startPosition, this.endPosition])
      this.state.push([...this.arrows])
      this.renderer.style.display = 'none'
      this.refresh()
    }
  }

  keydown (e) {
    if (!this.source) {
      return
    }
    if ((window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && e.keyCode === 83) {
      e.preventDefault()
      const a = this.el.querySelector('a.download')
      a.setAttribute('href', this.img.src)
      a.setAttribute('download', this.metadata.filename)
      a.click()
    }
    if ((window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && e.keyCode === 67) {
      e.preventDefault()
      this.el.setAttribute('contenteditable', true)
      const selection = window.getSelection()
      const range = document.createRange()
      range.selectNodeContents(this.img)
      selection.removeAllRanges()
      selection.addRange(range)
      document.execCommand('copy')

      window.getSelection().removeAllRanges()
      this.el.setAttribute('contenteditable', false)
    }
  }
}
