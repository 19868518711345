
export function Minimal (ctx, from, to, style) {
  ctx.lineWidth = 5
  ctx.lineCap = 'round'
  ctx.lineJoin = 'round'
  ctx.strokeStyle = 'red'
  ctx.beginPath()

  const delta = { x: to.x - from.x, y: to.y - from.y }
  const length = Math.sqrt(delta.x ** 2 + delta.y ** 2)
  const normal = { x: delta.x / length, y: delta.y / length }
  const perp0 = { x: normal.y, y: -normal.x }
  const headSize = Math.max(10, length * 0.1)
  const head0 = { x: to.x - normal.x * headSize + perp0.x * headSize, y: to.y - normal.y * headSize + perp0.y * headSize }

  ctx.moveTo(from.x, from.y)
  ctx.lineTo(to.x, to.y)
  ctx.lineTo(head0.x, head0.y)
  ctx.stroke()
}

export function SimpleRounded (ctx, from, to, style) {
  const delta = { x: to.x - from.x, y: to.y - from.y }
  const length = Math.sqrt(delta.x ** 2 + delta.y ** 2)
  const normal = { x: delta.x / length, y: delta.y / length }
  const perp0 = { x: normal.y, y: -normal.x }
  const perp1 = { x: -normal.y, y: normal.x }
  const headSize = Math.max(10, length * 0.1)
  const head0 = { x: to.x - normal.x * headSize + perp0.x * headSize, y: to.y - normal.y * headSize + perp0.y * headSize }
  const head1 = { x: to.x - normal.x * headSize + perp1.x * headSize, y: to.y - normal.y * headSize + perp1.y * headSize }
  const inner0 = { x: to.x - normal.x * headSize * 0.8 + perp0.x * headSize * 0.3, y: to.y - normal.y * headSize * 0.8 + perp0.y * headSize * 0.3 }
  const inner1 = { x: to.x - normal.x * headSize * 0.8 + perp1.x * headSize * 0.3, y: to.y - normal.y * headSize * 0.8 + perp1.y * headSize * 0.3 }

  ctx.lineWidth = headSize / 4
  ctx.lineCap = 'round'
  ctx.lineJoin = 'round'
  ctx.fillStyle = 'red'
  ctx.strokeStyle = 'red'

  ctx.beginPath()
  ctx.moveTo(from.x, from.y)
  ctx.lineTo(inner0.x, inner0.y)
  ctx.lineTo(head0.x, head0.y)
  ctx.lineTo(to.x, to.y)
  ctx.lineTo(head1.x, head1.y)
  ctx.lineTo(inner1.x, inner1.y)
  ctx.shadowColor = 'black'
  ctx.shadowBlur = headSize
  ctx.fill()
  ctx.shadowBlur = 0
  ctx.lineTo(from.x, from.y)
  ctx.stroke()
}
