/* global FileReader, Image */
import 'babel-polyfill'

import '/style.css'
import Editor from '/editor'

const imageMimePrefix = 'image/'

document.addEventListener('DOMContentLoaded', () => {
  const landingpad = document.getElementById('landingpad')
  const editor = new Editor('#editor')

  landingpad.addEventListener('dragover', e => {
    e.preventDefault()
  })

  const load = file => {
    const reader = new FileReader()
    reader.onload = e => {
      const image = new Image()
      image.onload = () => {
        editor.reset(image, file.type, file.name)
        landingpad.classList.add('minimized')
        landingpad.classList.remove('loading')
      }
      image.src = e.target.result
    }
    reader.readAsDataURL(file)
  }

  landingpad.addEventListener('drop', e => {
    e.preventDefault()
    landingpad.classList.add('loading')
    if (e.dataTransfer) {
      Array.from(e.dataTransfer.files).forEach(file => {
        if (file.type.startsWith(imageMimePrefix)) {
          load(file)
        }
      })
    }
  })

  document.addEventListener('paste', e => {
    e.preventDefault()
    if (e.clipboardData) {
      Array.from(e.clipboardData.items).forEach(item => {
        if (item.type.startsWith(imageMimePrefix)) {
          landingpad.classList.add('loading')
          load(item.getAsFile())
        }
      })
    }
  })
})
